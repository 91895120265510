/* eslint-disable */
export default {
  init() {

    // JavaScript to be fired on all pages

    $(function () {
      /* MOBILE MENU TOGGLE */
      $('.nav-small').hide();
      $('.nav-mobile > .menu-item-has-children > .sub-menu').hide();

      $('<span class="toggle-submenu show-submenu"></span>').insertAfter('.nav-mobile > .menu-item-has-children > a');

      $('.nav-mobile > .menu-item-has-children').click(function() {
        $('> .sub-menu', this).toggle();
      });

      $('#toggle-button').click(function() {
        $('.nav-small').toggle();
      });

      var showSubMenu = function() {
        $('.menu-item-has-children').toggleClass('show-sub-menu');

        if($(this).hasClass("show-sub-menu")) {
          $('> .sub-menu', this).addClass('submenu-visible');
        } else {
          $('> .sub-menu', this).removeClass('submenu-visible');
        }
      }

      $('.menu-item-has-children').click(showSubMenu);

      /* MOVE JETPACK ICONS */
      $('#post-share').html( $('.sharedaddy').detach() );
      $('.share-icon > span').remove();
      
      /* LOAD CATEGORIES */

      $("#load-more").click( function () {
        $('.post-archive__categories-list').toggleClass('categories-expanded');

        if ($(this).text() == "Load More")
          $(this).text("Hide")
        else
          $(this).text("Load More");
      });

    })},

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};



